import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxRsiChartTemplate from '@/templates/crypto/xxx-rsi-chart';

export default function SolanaRsiChart({ location }: GatsbyPageProps) {
  return (
    <XxxRsiChartTemplate
      coinAbbreviation="SOL"
      coinFullName="Solana"
      token={Token.SOLUSDT}
      location={location}
      articleId={ArticleList.SOLANA_RSI_CHART}
    ></XxxRsiChartTemplate>
  );
}
